<script>
import {mapActions, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: 'SigningDocument',
    computed:{
      ...mapState('signingDocument', ['signingDocument_form'])
    },
    methods:{
      getCookie,
      ...mapActions('signingDocument', ['GET_DOCUMENTS_FOR_SIGNING'])
    },
    async mounted() {
      await this.GET_DOCUMENTS_FOR_SIGNING()
    }
  }
</script>

<template>
  <div class="container">
    <h4 class="text-center my-4">Список документов на подписание</h4>
    <div class="my-3" v-if="signingDocument_form.documentsForSigning.length > 0">
      <DataTable :value="signingDocument_form.documentsForSigning" :paginator="true" :rows="50"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[50,100,150]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column header="№">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="full_name" header="ФИО"></Column>
        <Column header="Ссылка на документ">
          <template #body="{data}">
            <a :href="data.file_url + `&access-token=${getCookie('ACCESS_TOKEN')}`" target="_blank">Документ</a>
          </template>
        </Column>
        <Column field="sign_creator_user_date" header="Дата подписания создателя документа"></Column>
        <Column field="sign_user_date" header="Моя дата подписи"></Column>
        <Column field="category.category_name" header="Категория"></Column>
        <Column header="Статус">
          <template #body="{data}">
            <p class="m-0 text-secondary" v-if="data.status == 0">Ожидает</p>
            <p class="m-0 text-success" v-else-if="data.status == 1">Подтверждено</p>
            <p class="m-0 text-danger" v-else>Отклонено</p>
          </template>
        </Column>
        <Column header="Действия">
          <template #body="{data}">
            <router-link :to="`/ncalayer?file_id=${data.file_id}&category=${data.category.category_type}&sign_id=${data.id}`">
              <Button label="Действия"/>
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
    <h5 class="my-3" v-else>Документов для подписания нет</h5>
  </div>
</template>

<style scoped>

</style>